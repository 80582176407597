@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 100;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-thin.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-thin.woff') format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 100;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-thin-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-thin-italic.woff')
      format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-it-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-it-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-light.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-light.woff') format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-light-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-light-italic.woff')
      format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-it-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-it-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-regular.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-regular.woff')
      format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-italic.woff')
      format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-it-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-bk-it-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-medium.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-medium.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 500;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-medium-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-medium-italic.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-bold.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-bold.woff') format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-dm-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-dm-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-bold-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-bold-italic.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-bold.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-bold.woff') format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-dm-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-dm-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 700;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-bold-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-bold-italic.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: normal;
  font-weight: 900;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-black.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-black.woff') format('woff'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-dm-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/franklin-gothic-dm-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Sans';
  font-style: italic;
  font-weight: 900;
  src: url('/webfiles/latest/assets/ampcom/fonts/roboto-black-italic.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/ampcom/fonts/roboto-black-italic.woff')
      format('woff');
}

/* Serif fonts (AMP) */

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Serif';
  font-style: normal;
  font-weight: 400;
  src: url('/webfiles/latest/assets/adel/fonts/publico-headline-roman-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/publico-headline-roman-woff.woff')
      format('woff');
}

@font-face {
  font-display: auto;
  font-family: 'Ameriprise Serif';
  font-style: italic;
  font-weight: 400;
  src: url('/webfiles/latest/assets/adel/fonts/publico-headline-it-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/publico-headline-it-woff.woff')
      format('woff');
}

/* Icon fonts (AMP) */

@font-face {
  font-display: auto;
  font-family: 'Icons';
  font-style: normal;
  font-weight: normal;
  src: url('/webfiles/latest/assets/adel/fonts/ameriprise-icon-font-woff2.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/ameriprise-icon-font-woff.woff')
      format('woff');
}

/* Temporary add until we update ADEL to reference material UI icons */
@font-face {
  font-family: 'ADEL Material Symbols';
  font-style: normal;
  src: url('/webfiles/latest/assets/ampcom/fonts/material-icons.woff2')
      format('woff2'),
    url('/webfiles/latest/assets/adel/fonts/material-icons.woff2')
      format('woff2');
}

.amp-scope {
  & h1,
  & h2 {
    color: #003d6d;
  }
}
/* override Bootstap setting of HTML to 10 px -sister sites*/
html {
  font-size: 1rem !important;
}
